.Header-logo {
    pointer-events: none;
    width:'200px';
    height:'94px';
    font-family: "Maersk Headline";
  }
  .text {
    font-family: 'Maersk Text';
  }
  .Button {
    width: 150px;
    background: #424242;
    font-size: 40px;
    margin: 100px auto;
    padding: 10px;
}


.Button:hover {
    background: #caa43c;
}

.hide {
  display: none;
}

.myDIV:hover + .hide {
  display: block;
  color: red;
}

.menu-item:hover{
  background-color: rgb(238, 235, 235) !important;
}