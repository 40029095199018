
.topContainerTestType{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    margin-top: 0.5px;
    padding: 0.1%;
}
.formContainerTestType{
    width: 105%;
    border: 0.5px;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);   
}

.formLabel{
    margin-Top: 10px;
    margin-bottom: 10px;
    padding: 10px;
}

.input{
    margin-bottom: 10px;
    border-top: none;
    border-left: none;
    border-right: none; 
}
.selectButton{
    width: min-content !important;
    border: none !important;
    margin-block-end: auto !important;
}
.selectButton::after{
    box-shadow: none !important
}
.buttonContainer{
    display: flex;
}

.submitButton{
    background-color: #B5E0F5;
    border: none;
    color: black;
    width:10%;
    float: right !important;
    
}
.submitButton:hover{

    background-color: #00243D;
    color: #FFF;
}
h2{
    margin-bottom: 30px;
}

@media only screen and (max-width: 1370px){
.topContainerTestType{
    margin-top: -105px;
}
.formContainerTestType{
    font-size: 15px;
}
.inputBox{
    margin-bottom: 25px;
    font-size: 13px;
}
}