@import "~@maersk-global/fonts/maeu/fonts.css";
@import "~@maersk-global/mds-design-tokens/maersk/light/css/design-tokens-px.css";
@import "~@maersk-global/mds-foundations/css/foundations.css";

/* Naming convention: component-name__class-name */
.app__layout {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app__navigation-page-layout {
  display: flex;
  flex-grow: 1;
  align-items: stretch;
}

.app__navigation-layout {
  min-width: 300px;
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  border-radius: 4px;
}

.app__navigation-layout-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.app__page-layout {
  margin: 32px;
  flex-grow: 1;
}

.app__page-layout > div {
  width: 60%;
}

.app__page-title {
  margin-bottom: 24px !important;
}

.app__page-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px;
}

.app__page-input {
  margin-bottom: 8px;
}

.app__page-table-pagination {
  display: flex;
  justify-content: left;
  margin-top: 8px;
}

.app__page-footer {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.notification__error {
  margin-bottom: 8px;
}
