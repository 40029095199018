
.topContainerTestType{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    min-height: 100vh;
    /* margin-top: 45%; */
    position: absolute;
    top: 65%;
    left: 30%;
    padding: 0.1%;

}
.formContainerTestType{
    width: 85%;
    min-height: 90vh;
    border: 0.5px;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);   
}

.formLable{
    margin-bottom: 20px
}

.input {
    margin-bottom: 50px;
    border-top: none;
    border-left: none;
    border-right: none;
    font-family: 'Maersk Text';
    width: 100%;
  }

.selectButton{
    width: min-content !important;
    border: none !important;
    margin-block-end: auto !important;
}
.selectButton::after{
    box-shadow: none !important
}
.buttonContainer{
    display: flex;
}

.submitButton{
    background-color: #B5E0F5;
    border: none;
    color: black;
    width:10%;
    float: right !important;
    
}
.submitButton:hover{

    background-color: #00243D;
    color: #FFF;
}
h2{
    margin-bottom: 30px;
}

@media only screen and (max-width: 1370px){
.topContainerTestType{
    margin-top: -105px;
}
.formContainerTestType{
    font-size: 15px;
}
.input {
    margin-bottom: 25px;
    font-size: 13px;
    font-family: 'Maersk Text';
    width: 100%;
  }
}