html,
body,
#app {
  height: 100%;
  box-sizing: border-box;
}
body {
  padding: 0;
  margin: 0;
}
.layout {
  display: flex;
  text-align: left;
  left: 20%;
}
.layout__column {
  flex-direction: column;
}
.layout__row {
  flex-direction: row;
}
.layout--center {
 left: 80%;
  margin: 0 auto;
  /* justify-content: center; */
}
.layout--left {
  /* left: 0%;
   margin: 0 auto; */
   position: absolute;
   top: 90%;
   left: -5%;
   width: 100%
   /* justify-content: center; */
 }
.layout--v-middle {
  height: 100%;
  align-items: center;
}
.layout--v-bottom {
  align-items: flex-end;
}
.layout--space-evenly {
  justify-content: space-evenly;
}
.layout--xs {
  width: 25%;
}
.layout--s {
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: white;
  gap: 0px;
  left: 30%;
  position: absolute;
}
.layout--m {
  width: 75%;
}
.layout--margin-16 {
  padding: 16px;
}
.layout--margin-32 {
  padding: 12px;
  left: 30%;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.hide {
  display: none;
}
.field {
  margin: 16px 0;
  display: block;
}
.app__item {
  margin: 16px;
}
.app__item figure img,
.app__item figure svg {
  -webkit-filter: grayscale(0) blur(0);
  filter: grayscale(0) blur(0);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  width: 100%;
}
.app__item a:hover figure img,
.app__item a:hover figure svg {
  -webkit-filter: grayscale(100%) blur(3px);
  filter: grayscale(100%) blur(3px);
}
a {
  color: #0073ab;
}
.nav--link {
  position: absolute;
  top: 16px;
  left: 16px;
}
.button-group {
  display: flex;
  margin-top: 0 !important;
}
.button-group mc-button {
  margin-right: 16px;
}
.button-group mc-button:last-child {
  margin-right: 0;
}
.size-selector {
  position: absolute;
  top: 32px;
  right: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.paginated-data-wrapper h1 {
  margin-bottom: var(--mdt-core-spacing-12px);
  font-size: var(--mdt-brand-font--display-2-font-size);
  line-height: var(--mdt-brand-font--display-2-font-line-height);
}
.paginated-data-wrapper .pagination-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 4px;
}

#tagsContainer {
  display: flex;
  margin: 8px 0;
}
#tagsContainer mc-tag {
  margin-right: 16px;
}
