.welcometitle {
    justify-content: center;
    align-items: center;
    font-family: 'Maersk Headline';
    color: #00243d;
    background-color: #cce9f7;
    font-weight: 700;
    letter-spacing: 0.1em;
    word-spacing: 0.4em;
    font-size: 20px;
    width: 35em;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 3.5em 1.3em;
    border-radius: 0.4em;
    box-shadow: 0 0.3em 0.5em rgb(0 0 0 / 0.2);
  }
  
  .topContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin-top: 2px;
    font-family: 'Maersk Headline';
    font-size: 22px;
  }
  .formContainer {
    width: 40%;
    border: 2px;
    border-radius: 5px;
    padding: 30px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    font-family: 'Maersk Headline';
    font-size: 16px;
  }
  .formLable {
    margin-bottom: 20px;
    font-family: 'Maersk Text';
  }
  
  .input {
    margin-bottom: 50px;
    border-top: none;
    border-left: none;
    border-right: none;
    font-family: 'Maersk Text';
    width: 100%;
  }
  .selectButton {
    width: min-content !important;
    border: none !important;
    margin-block-end: auto !important;
    font-family: 'Maersk Text';
  }
  .selectButton::after {
    box-shadow: none !important;
  }
  .buttonContainer {
    display: flex;
    /* justify-content: center; */
  }
  
  .submitButton {
    background-color: #b5e0f5;
    border: none;
    color: black;
    width: 10%;
  }
  .submitButton:hover {
    background-color: #00243d;
    color: #fff;
  }
  h2 {
    margin-bottom: 30px;
  }
  .formLabel {
    font-family: 'Maersk Text';
    font-size: 15px;
  }
  .headerText {
    margin-top: 24px;
    font-family: 'Maersk Headline';
    font-size: 18px;
    font-weight: bold;
  }
  
  @media only screen and (max-width: 1370px) {
    .topContainer {
      margin-top: 65px;
    }
    .formContainer {
      font-size: 15px;
      font-family: 'Maersk Text';
    }
    .input {
      margin-bottom: 25px;
      font-size: 13px;
      font-family: 'Maersk Text';
      width: 100%;
    }
  }
  .folderSubmitBtn {
    width: 69px;
    color: aliceblue;
    height: 34px;
    background-color: rgb(30, 133, 229);
    font-size: 15px;
    /* border-radius: 23%; */
  }
  